<template>
  <div id="container-register" class="container full-height fx fx-center">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>

    <div id="login" class="fx fx-center fx-direct-cont full height">
      <router-link :to="{ name: 'home'}" class="fx fx-a-i-cent">
        <div class="fx fx-center pd-1-em mg-0-75-bot logo">
          <img :src="baseUrl + 'img/logo.png'" :alt="app_name + ' logo'" />
        </div>
      </router-link>

      <div class="wd-100">
        <form id="supplier" name="form">
          <div>
     
            <!-- <small v-if="this.form.name || !this.form.cpf && this.errorMessage" class="info" :class="formIndex == 0 ? 'current_form' : formIndex > 0 ? 'prev_form dsp-none' : 'dsp-none'"> 
              {{ this.errorMessage }}
            </small> -->

            <div
              class="fx fx-direct-cont"
              :class="formIndex == 0 ? 'current_form' : formIndex > 0 ? 'prev_form dsp-none' : 'dsp-none'"
            >

              <div class="company-data">
                <div class="fx fx-center fx-start fx-direct-cont">
                  <div class="radio-btn-group fx fx-main wd-100">

                    <div class="radio">
                      <input
                        type="radio"
                        name="p_variation"
                        value="INDIVIDUAL"
                        v-model="form.variation"
                        id="radio-pf"
                        class="form-radio"
                      />
                      <label for="radio-pf" class="label-style">Pessoa Física</label>
           
                    </div>

                    <div class="radio">
                      <input
                        type="radio"
                        name="p_variation"
                        value="COMPANY"
                        v-model="form.variation"
                        id="radio-pj"
                        class="form-radio"
                      />
                      <label for="radio-pj" class="label-style">Pessoa Jurídica</label>
                    </div>
                  </div>
          
                </div>
                <br />
              </div>

              <div class="company-data">
                <!-- mask="##.###.###/#####-##" -->
                <div class="input-style mg-0-75-bot" v-if="form.variation === 'COMPANY'" v-bind:class="{ 'red': !this.form.cnpj }">
                  <the-mask
                    mask="##.###.###/####-##"
                    type="text"
                    :masked="true"
                    name="cnpj"
                    id="cnpj"
                    v-model="form.cnpj"
                    placeholder="CNPJ"
                  ></the-mask>
                </div>
                  <small v-if="form.cnpj === '' && this.errorMessage && form.variation === 'COMPANY'" class="info"> 
                    O CNPJ é obrigatório.
                  </small>
              </div>

              
                <div class="input-style mg-0-75-bot" v-bind:class="{ 'red': !this.form.name }">
                  <input
                    class="c-ui-input__field mg-0-75-bot"
                    type="text"
                    name="name"
                    v-model="form.name"
                    placeholder="Nome Completo"
                  />
                  <small v-if="!form.name && this.errorMessage" class="info"> 
                    O Nome Completo é obrigatório.
                  </small>
                </div>

              <div class="input-style mg-0-75-bot" v-if="form.variation === 'INDIVIDUAL'" v-bind:class="{ 'red': !this.form.cpf }">
                <the-mask
                  mask="###.###.###-##"
                  type="text"
                  :masked="true"
                  name="cpf"
                  id="cpf"
                  v-model="form.cpf"
                  class="c-ui-input__field"
                  placeholder="CPF"
                ></the-mask>
                   <small v-if="form.cpf === '' && this.errorMessage && form.variation === 'INDIVIDUAL'" class="info"> 
                    O CPF é obrigatório.
                  </small>
              </div>
          
              <div class="input-style mg-0-75-bot" v-if="form.variation === 'COMPANY'" v-bind:class="{ 'red': !this.form.company_name }">
                <div>
                  <input
                      class="c-ui-input__field"
                      type="text"
                      placeholder="Razão Social"
                      name="company_name"
                      v-model="form.company_name"
                    />
                  
                  <small v-if="form.company_name === '' && this.errorMessage && form.variation === 'COMPANY'" class="info"> 
                    A Razão Social é obrigatória.
                  </small>
                </div>
              </div>

              <div class="input-style mg-0-75-bot" v-if="form.variation === 'COMPANY'" v-bind:class="{ 'red': !this.form.trading_name }">
                <div>
                  <div>
                    <input
                      
                      class="c-ui-input__field"
                      type="text"
                      name="trading_name"
                      v-model="form.trading_name"
                      placeholder="Nome Fantasia"
                    />
                  </div>
                  <small v-if="form.trading_name === '' && this.errorMessage && form.variation === 'COMPANY'" class="info"> 
                    O Nome Fantasia é obrigatório.
                  </small>
                </div>
              </div>

              <div class="input-style mg-0-75-bot" v-if="form.variation === 'COMPANY'" v-bind:class="{ 'red': !this.form.ie }">
                <div>
                  <div>
                    <input
                      class="c-ui-input__field"
                      type="text"
                      name="ie"
                      v-model="form.ie"
                      placeholder="Inscrição Estadual"
                    />
                  </div>
                   <small v-if="form.ie === '' && this.errorMessage && form.variation === 'COMPANY'" class="info"> 
                    A Inscrição Estadual é obrigatória.
                  </small>
                </div>
              </div>

              <div class="input-style mg-0-75-bot" v-if="form.variation === 'COMPANY'" v-bind:class="{ 'red': !this.form.activity }">
                <input
                  class="c-ui-input__field"
                  type="text"
                  name="activity"
                  v-model="form.activity"
                  placeholder="Principal Atividade"
                />
                  <small v-if="form.activity === '' && this.errorMessage && form.variation === 'COMPANY'" class="info"> 
                    A Principal Atividade é obrigatória.
                  </small>
              </div>

                            
              <div class="input-style mg-0-75-bot"
              v-bind:class="{ 'red': !this.form.phone }">
                <vue-tel-input
                  v-model="form.phone"
                  name="phone"
                  id="phone"
                  :defaultCountry="'BR'"
                  :required="true"
                  :placeholder="'Insira um Telefone'"
                  :dynamicPlaceholder="false"
                  :enabledCountryCode="true"
                  :wrapperClasses="''"
                  class="c-ui-input__field wd-100 none-border"
                  @country-changed="telCountryChanged"
                ></vue-tel-input>
              </div>
              <small v-if="form.phone === '' && this.errorMessage" class="info" style="text-align: left!important; margin: -15px 0px 0px 10px"> 
                O Telefone é obrigatório.
              </small>

              <div class="input-style mg-0-75-bot" v-bind:class="{ 'red': !this.form.email}">
                <input
                  type="text"
                  class="c-ui-input__field"
                  name="email"
                  placeholder="Email"
                  v-model="form.email"
                />
                 <small v-if="form.email === '' && this.errorMessage" class="info"> 
                   O E-mail é obrigatório.
                </small>
              </div>

              <div class="input-style" v-bind:class="{ 'red': !this.form.password }">
                <input
                  type="password"
                  class="c-ui-input__field"
                  name="password"
                  placeholder="Senha"
                  v-model="form.password"
                />
                 <small v-if="form.password === '' && this.errorMessage" class="info"> 
                   A senha é obrigatória.
                </small>
              </div>

              
            </div>


            

            <div
              class="company-data fx fx-direct-cont"
              v-if="form.business_address"
              :class="formIndex === 1 ? 'current_form' : formIndex > 1 ? 'prev_form dsp-none' : 'dsp-none'"
            >
              <div>
                <div class="input-style wd-100 mg-0-75-bot">
                  <the-mask
                    v-bind:class="{ 'red': !form.business_address.zipcode }"
                    mask="#####-###"
                    type="text"
                    :masked="true"
                    name="business_address.zipcode"
                    id="business_address.zipcode"
                    @change.native="cepera"
                    @keyup.native="cepera"
                    v-model="form.business_address.zipcode"
                    class="c-ui-input__field wd-100"
                    placeholder="CEP"
                  ></the-mask>
                </div>
                 <small v-if="form.business_address.zipcode === '' && this.errorMessage && !isCepValid" class="info"> 
                   Atenção: Informe o campo CEP corretamente.
                </small>
              </div>
              <small v-if="errorMessage && !isCepValid" class="info">{{this.errorMessage}}</small>
              <div v-show="isCepValid" class="fx fx-direct-cont wd-100">
                <div>
                  <div class="input-style wd-100 mg-0-75-bot">
                    <input
                      v-bind:class="{ 'red': !form.business_address.state }"
                      type="text"
                      class="c-ui-input__field"
                      name="business_address.state"
                      v-model="form.business_address.state"
                      placeholder="Estado"
                    />
                  </div>
                 <small v-if="form.business_address.state === '' && this.errorMessage && isCepValid" class="info"> 
                   O Estado é obrigatório.
                </small>
                </div>
                <div>
                  <div class="input-style wd-100 mg-0-75-bot">
                    <input
                      v-bind:class="{ 'red': !form.business_address.city }"
                      type="text"
                      class="c-ui-input__field"
                      name="business_address.city"
                      v-model="form.business_address.city"
                      placeholder="Cidade"
                    />
                  </div>
                  <small v-if="form.business_address.city === '' && this.errorMessage && isCepValid" class="info"> 
                   A Cidade é obrigatória.
                 </small>
                </div>
                <div>
                  <div class="input-style wd-100 mg-0-75-bot">
                    <input
                      v-bind:class="{ 'red': !form.business_address.neighborhood }"
                      type="text"
                      class="c-ui-input__field"
                      name="business_address.neighborhood"
                      v-model="form.business_address.neighborhood"
                      placeholder="Bairro"
                    />
                  </div>
                  <small v-if="form.business_address.neighborhood === '' && this.errorMessage && isCepValid" class="info"> 
                   O Bairro é obrigatório.
                 </small>
                </div>
                <div>
                  <div class="input-style wd-100 mg-0-75-bot">
                    <input
                      v-bind:class="{ 'red': !form.business_address.address }"
                      type="text"
                      class="c-ui-input__field"
                      name="business_address.address"
                      v-model="form.business_address.address"
                      placeholder="Rua"
                    />
                  </div>
                  <small v-if="form.business_address.address === '' && this.errorMessage && isCepValid" class="info"> 
                   A Rua é obrigatória.
                 </small>
                </div>
                <div>
                  <div class="input-style wd-100 mg-0-75-bot">
                    <input
                      v-bind:class="{ 'red': !form.business_address.number }"
                      type="text"
                      class="c-ui-input__field"
                      name="business_address.number"
                      v-model="form.business_address.number"
                      placeholder="Número"
                    />
                  </div>
                  <small v-if="form.business_address.number === '' && this.errorMessage && isCepValid" class="info"> 
                   O Número é obrigatório.
                 </small>
                </div>
                <div>
                  <div class="input-style wd-100 mg-0-75-bot">
                    <input
                      v-bind:class="{ 'red': !form.business_address.complement }"
                      type="text"
                      class="c-ui-input__field"
                      name="business_address.complement"
                      v-model="form.business_address.complement"
                      placeholder="Complemento"
                    />
                  </div>
                  <small v-if="form.business_address.complement === '' && this.errorMessage && isCepValid" class="info"> 
                   O Complemento é obrigatório.
                 </small>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- <div></div> -->
    <div class="fixed-buttons relative container">
      <div class="fx fx-j-c-s-b fx-a-i-cent mg-5px">
        <div class="p-detail-btn clickable" @click="backRot">
          <i class="fa fa-angle-left"></i>
        </div>
        <div class="p-detail-btn clickable mg-5px" @click="registerStoreKeeper">
          Próximo
          <i class="fa fa-angle-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
div#login,
div#container-register {
  width: 30em;
}

div#phone {
  border: 0;
}
.input-style input {
  border-radius: 0;
  margin: 0;
}

.fixed-buttons.fixed.container {
  padding: 0 1em;
}

.mg-5px {
  margin: 5px;
}

#container-register {
  display: grid !important;
  grid-template-rows: 1fr 35%;
}

@media (max-width: 500px) {
  .relative {
    position: relative;
  }

  .isMobile {
    height: 40rem !important;
    display: flex;
    flex-direction: column;
  }

  @media (max-height: 600px) {
    #container-register {
      grid-template-rows: 1fr 35%;
    }
  }
}
.red {
  border-bottom: 1px solid #bf0000 !important;
}

</style>

<style>
input {
  width: 100%;
}
.info, .center-text{
  text-align: center!important;
  padding: 10px;
}

.none-border {
    border: 0;
    outline: none;
    box-shadow: 0px 0px 0px 0px !important;
    position: relative;
    top: -6px;
}
</style>

<script>
import router from "@/router";
import { apiBaseUrl } from "@/services/api.js";
import util from "@/services/util";
import { debounce } from "debounce";
import axios from "axios";
import { TheMask } from "vue-the-mask";
import Auth from "@/services/auth.js";
import boolean from "boolean";
import HeaderBuilder from "@/services/header-builder.js";
import { VueTelInput } from "vue-tel-input";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet

import Features from "@/services/features";

export default {
  data: function () {
    return {
      baseUrl: process.env.BASE_URL,
      app_name: process.env.VUE_APP_NAME,
      tenant_id: process.env.VUE_APP_TENANT_ID,
      formIndex: 0,
      
      isLoading: false,
      isCepValid: false,
      lastCep: null,
      telDialCode: null,
      enabled_external_logins: null,
      errorMessage: "",
      form: {
        variation: "INDIVIDUAL",
        password: "",
        name: "",
        email: "",
        company_name: "",
        trading_name: "",
        cnpj: "",
        cpf: "",
        ie: "",
        phone: "",
        activity: "",
        business_address: {
          number: "",
          zipcode: "",
          address: "",
          neighborhood: "",
          city: "",
          state: "",
          complement: "",
        },
      },
    };
  },
  mounted() {
    this.errorMessage = ""
    this.$root.$emit("showOnlyRouter");
    this.form.name = this.init_name;
    this.form.email = this.init_email;
    Features.get().then((features) => {
      if (!features.user_address || !features.user_address.mandatory) {
        this.form.business_address = null;
      }
      this.enabled_external_logins = Object.entries(features.auth_providers)
        .filter(([n, p]) => p.enabled)
        .map(([n, p]) => n);
    });
    // Redirected OAuth
    if (this.provider) {
      this.isLoading = true;
      window.opener.parent.focus();
      window.setTimeout(() => {
        this.continueExternalAuth(this.provider, this.code);
      }, 5000);
    }
  },
  beforeDestroy() {
    this.errorMessage = ""
    this.$root.$emit("showWholeApp");
  },
  props: {
    then: Object, // Route or RouteReference
    init_name: String,
    init_email: String,
    hide_social: Boolean,
    // Redirected OAuth
    provider: String,
    code: String,
  },
  methods: {
    telCountryChanged: function (countryObj) {
      this.telDialCode = countryObj.dialCode;
    },
    backRot: function () {
      if (this.formIndex == 0) {
        router.go(-1);
      }
      this.formIndex--;
    },
    cepera: function () {
      if (!this.form.business_address) {
        return;
      }
      var cep = this.form.business_address.zipcode.replace(/\D/, "");
      if (cep === this.lastCep) {
        return;
      }
      this.lastCep = cep;
      this.isCepValid = false;
      if (cep.length != 8) {
        return;
      }
      this.isLoading = true;
      axios
        .get("https://viacep.com.br/ws/" + cep + "/json")
        .then((response) => {
          var dados = response.data;
          if (!("erro" in dados)) {
            // Atualiza os campos com os valores da consulta.
            this.form.business_address.address = dados.logradouro;
            this.form.business_address.neighborhood = dados.bairro;
            this.form.business_address.city = dados.localidade;
            this.form.business_address.state = dados.uf;
            this.isCepValid = true;
            this.$forceUpdate();
          } else {
            this.$notify({
              duration : 24000,
              type: "error",
              title: "CEP Inválido!",
            });
          }
        })
        .finally(() => (this.isLoading = false));
    },
    doAuthWithProvider: debounce(
      function (provider) {
        this.isLoading = true;
        this.$auth
          .authenticate(provider)
          .then((data) => {
            this.isLoading = false;
            if (!data.code) {
              throw "Impossível obter código";
            }
            this.continueExternalAuth(provider, data.code);
          })
          .catch((err) => {
            this.isLoading = false;
            this.$notify({
              duration : 24000,
              type: "error",
              title: "Erro na interação com " + provider,
              text: util.stringifyAxiosError(err),
            });
          });
      },
      2000,
      true
    ),
    continueExternalAuth: function (provider, code) {
      this.isLoading = true;
      Auth.getProviderRedirectUri(provider).then((apiPathedRedirectUri) => {
        const params = new URLSearchParams({
          code: code,
          useRedirectUri: apiPathedRedirectUri,
        });
        axios
          .get(
            `${apiBaseUrl}/api/user/externalLogin/${provider}/callback?${params.toString()}`,
            {
              headers: HeaderBuilder.build(),
            }
          )
          .then((response) => {
            this.isLoading = false;
            if (response.data.exists === false) {
              router.push({
                name: "register-storekeeper-account",
                params: {
                  init_name: response.data.name,
                  init_email: response.data.email,
                  then: this.then,
                  hide_social: true,
                },
              });
              return;
            }
            Auth.loginWithData(
              { token: response.data.token, type: response.data.type },
              true,
              this.then
            ).then(() => {
              this.$root.$emit("cartUpdate");
            });
          })
          .catch((err) => {
            this.isLoading = false;
            this.$notify({
              duration : 24000,
              type: "error",
              title: "Erro no Login com " + provider,
              text: util.stringifyAxiosError(err),
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    registerStoreKeeper: debounce(
      function () {
        this.isLoading = true;
        this.errorMessage = ''
        const formCopy = JSON.parse(JSON.stringify(this.form));
        if (!(formCopy.phone || "").includes("+")) {
          formCopy.phone =
            String(this.telDialCode).replace(/\D/g, "") + formCopy.phone;
        }
        axios
          .post(`${apiBaseUrl}/api/user/storekeeper/register`, formCopy, {
            headers: HeaderBuilder.build(),
          })
          .then(
            (response) => {
              Features.get().then((features) => {
                this.errorMessage = ''
                if (
                  features.verification &&
                  boolean(features.verification.required)
                ) {
                  // TODO: "seu e-mail para obter acesso"
                  router.push({ name: "home" });
                  return;
                }
                if (features.approval && boolean(features.approval.required)) {
                  // TODO: "Aguarde aprovação de sua conta antes de realizar login"
                  router.push({ name: "home" });
                  return;
                }
                // Nenhuma barreira: Já realizar o login automaticamente
                const payload = {
                  email: this.form.email,
                  password: this.form.password,
                };
                Auth.login(payload, true, this.then).then(() => {
                  this.$root.$emit("cartUpdate");
                });
              });
            },
            (error) => {
              let notif = false;
              if (!error.response.data.errors) {
                notif = true;
              }
              if (
                Array.from(
                  this.$el.querySelectorAll(
                    ".current_form input, .prev_form input"
                  )
                ).some((input) =>
                  error.response.data.errors.hasOwnProperty(
                    input.getAttribute("name")
                  )
                ) == false
              ) {
                this.formIndex++;
              } else {
                notif = true;
              }
              if (notif) {
                if (error.response.data.errors) {
                  error.response.data.errors = Object.keys(
                    error.response.data.errors
                  )
                    .filter((field) =>
                      Array.from(
                        this.$el.querySelectorAll(
                          ".current_form input, .prev_form input"
                        )
                      ).some((input) => field == input.getAttribute("name"))
                    )
                    .reduce(
                      (res, key) => (
                        (res[key] = error.response.data.errors[key]), res
                      ),
                      {}
                    );
                }
                this.errorMessage = [];
                for (var i in error.response.data.errors) {
                  if (error.response.data.errors.hasOwnProperty(i)) {
                    this.errorMessage.push(error.response.data.errors[i]);
                  }
                }
                this.errorMessage = this.errorMessage.toString()
                this.errorMessage.replace(/[[[]]+/g, '');
                this.errorMessage.replace(/[[,]]+/g, '');
                this.$notify({
                  duration : 24000,
                  type: "error",
                  title: "Erro ao Cadastrar!",
                  text: util.stringifyAxiosError(error),
                });
              }
            }
          )
          .finally(() => {
            this.isLoading = false;
          });
      },
      1000,
      true
    ),
  },
  components: {
    "the-mask": TheMask,
    Loading,
    VueTelInput,
  },
  computed: {
    formVariation: function () {
      return this.form.variation;
    },
  }  
};
</script>
